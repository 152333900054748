import React, { useEffect, useState } from 'react'
import { findProjectAdmin, updateContribution } from '../api'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Exporter from '../components/Exporter'
import Checkbox from '../components/UI/Checkbox'
import Loader from '../components/UI/Loader'
import NotFound from '../components/UI/NotFound'
import Title from '../components/UI/Title'
import Moment from 'moment'

const location = () => (typeof window !== `undefined`) ? window.location : { pathname: '' }
const match = (location().pathname.match(/moderation\/(\d+)_(.+)/) || [])
const projectId = match[1]
const token = match[2]

export default function Project() {
  const [project, setProject] = useState(null)
  useEffect(() => {
    findProjectAdmin(projectId, token).then(e => setProject(e))
  }, [])
  if (!project) return <Loader />
  if (project.error) return <NotFound />
  return ( 
    <div className="flex flex-col justify-center items-center w-full mx-auto" style={{ maxWidth: 900 }}>
      <Header project={project} />
      <Title>Modération {project.name}</Title>
      <div className="text-gray-500">{project.contributions.filter(c => c.message && c.message != '').length} contributions</div>
      <div className="my-12">
        {project.contributions.sort((a, b) => Moment(b.created_at).diff(Moment(a.created_at)) ).filter(c => c.message && c.message != '').map(contrib => (
          <div key={contrib.id} className="flex items-center my-6">
            <Checkbox
              checked={contrib.active}
              onClick={() =>
                updateContribution(contrib.id, token, { active: !contrib.active })
                .then(e => {
                  contrib.active = !contrib.active
                  setProject({ ...project })
                })
              }
              className="mr-6" />
            {contrib.medias &&
              <img src={contrib.medias.url} className="w-32 rounded" />
            }
            {!contrib.medias &&
              <div className="w-32 h-32 flex-shrink-0 bg-gray-400 flex items-center justify-center text-xs text-white rounded">Pas de photo</div>
            }
            <div className="ml-6 text-sm">
              <div className="text-xs text-gray-400">Participation le {Moment(contrib.created_at).format('DD/MM/YYYY')}</div>
              <div>{contrib.message}</div>
              <div className="text-gray-500">{contrib.name}</div>
            </div>
            {/* <Exporter contribution={contrib} project={project} /> */}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  )
}
